<template>
    <div class="news-card">
        <div>
            <img :src="newsData.image" alt="" />
            <div class="info">
                <span>{{ newsData.tag.name[locale] }}</span>
                <span>{{ new Date(newsData.published_at).toLocaleDateString('ru') }}</span>
            </div>
            <div class="title"> {{ newsData.title[locale] }}</div>
            <p class="content">
                {{ newsData.subtitle[locale] }}
            </p>
        </div>
        <NuxtLink :to="localePath('/news/' + newsData.id)">
            <button class="my-btn third">{{ t('readPromo') }}</button>
        </NuxtLink>
    </div>
</template>

<script setup>
const { locale, t } = useI18n()
defineProps(['newsData'])
const localePath = useLocalePath();
</script>